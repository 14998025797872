<template>
  <v-dialog v-model="new_account_dialog" width="800">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        elevation="2"
        color="#33cc99"
        class="white--text ml-10"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon left>mdi-plus</v-icon>
        Add New Account
      </v-btn>
    </template>
    <NewAccountModal
      :accounts="accounts"
      :modalType="modalType"
      @closeDialog="closeDialog($event)"
    />
  </v-dialog>
</template>
<script>
export default {
  name: "AddAccountButton",
  props: ["accounts", "modalType"],
  components: {
    NewAccountModal: () => import("./NewAccountModal"),
  },
  data() {
    return {
      new_account_dialog: false,
    };
  },
  methods: {
    closeDialog(new_account_dialog) {
      this.new_account_dialog = new_account_dialog;
    },
  },
};
</script>
